@font-face {
  font-family: 'Henny Penny';
  src: url(./Assets/Fonts/HennyPenny-Regular.ttf);
}

html, body {
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: url(./Assets/Images/bg-texture.png);
  font-family: 'Henny Penny', cursive;
  height: 85vh;
  overflow: hidden;
  position: relative;
}