div.help-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  transition: all 0.25s;
  background-color: rgba(130, 183, 189, 0.7);
}

div.help-overlay.hide {
  pointer-events: none;
  opacity: 0;
}

div.close-overlay {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: #b3a78f;
  border: 1px solid #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../Assets/Images/close-icon.svg);
  background-size: 45%;
}

div.help-overlay img {
  width: 90%;
  position: absolute;
}

div.help-overlay img:first-of-type {
  top: 10px;
  left: 10px;
}

div.help-overlay img:last-of-type {
  bottom: 16.5%;
  left: 5%;
}