div.game-board {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div.hint-container {
  width: 100%;
  text-align: center;
  margin-bottom: 2%;
}

div.hint-container p {
  padding: 0;
  margin: 0;
  font-size: 1.2em;
}

div.endgame-message {
  text-align: center;
  font-size: 3.5em;
}