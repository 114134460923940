div.mystery-word-container {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 5% auto;
  overflow: hidden;
  height: 9.5%;
}

div.blank-tile {
  background: rgba(232, 217, 188, 0.2);
  border-bottom: 5px solid rgba(0, 0, 0, 0.3);
  padding: 10px 2%;
  align-self: center;
  font-size: 100%;
  color: transparent;
  user-select: none;
  transition: all 0.125s;
}

div.dash-tile {
  padding: 10px 2%;
  align-self: center;
  font-size: 100%;
  color: rgba(232, 217, 188, 0.5);
  user-select: none;
}

div.blank-tile.uncovered {
  color: rgb(105, 151, 93);
  border-bottom: 5px solid rgba(159, 188, 152, 0.45);
}

div.blank-tile.not-found {
  color: #cd675b;
  border-bottom: 5px solid rgba(205, 103, 91, 0.45);
}