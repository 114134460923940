div.home-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: center;
}

img.logotype {
  width: 45vw;
  position: absolute;
  top: 14%;
  right: 24%;
  z-index: 0;
}

embed.fox {
  width: 87%;
  z-index: 1;
  margin: 40% auto 3%;
}

button {
  background-color: #b3a78f;
  color: #faf6f2;
  font-size: 1.1em;
  border: none;
  padding: 10px 20px;
  font-family: 'Henny Penny', cursive;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
}

div.home-screen p {
  color: #cd675b;
  padding: 0;
  margin: 0 0 11%;
  font-size: 18px;
}

div.home-screen button {
  position: relative;
  margin: 0 auto;
}

span.mobile-message {
  display: none;
}

@media(min-width: 600px) {
  div.home-screen {
    width: 85vh;
    left: calc(50% - 85vh/2);;
  }
  
  img.logotype {
    width: 46%;
    top: 9%;
  }

  embed.fox {
    margin: 30% auto 3%;
  }

  div.home-screen p {
    margin: 0 0 3%;
  }

  div.home-screen button {
    display: none;
  }

  span.mobile-message {
    display: unset;
  }
}