div.letter-bank {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  left: 0;
}

div.letter-tile {
  color: #ffffff;
  font-size: 100%;
  background: #82b7bd;
  padding: 8px 5px;
  text-align: center;
  align-self: center;
  width: 5.5%;
  margin: 0 5px 5px;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  transition: all 0.125s;
}

div.letter-tile.used {
  background: rgba(130, 183, 189, 0.25);
  pointer-events: none;
}

div.letter-tile.incorrect {
  background: rgba(216, 89, 89, 0.25);
  pointer-events: none;
}